import DashboardLayout from '../layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../pages/NotFoundPage.vue'

// Admin pages
// import Overview from 'src/pages/Overview.vue'
// import UserProfile from 'src/pages/UserProfile.vue'
// import TableList from 'src/pages/TableList.vue'
// import Typography from 'src/pages/Typography.vue'
// import Icons from 'src/pages/Icons.vue'
// import Notifications from 'src/pages/Notifications.vue'
// import Stake from 'src/pages/Stake.vue'
import Winner from 'src/pages/Winner.vue'


const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/winners'
  },
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/winners',
    children: [
      {
        path: 'winners',
        name: 'Winners',
        component: Winner
      }
    ]
  },
  { path: '*', component: NotFound }
]

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes
