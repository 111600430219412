<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row mt-2">
        <div class="col-12">
          <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
            <template slot="header">
              <h4 class="card-title">Winners</h4>
            </template>
            <div class="row">
              <div class="col col-12 ml-2 row">
                <div class="mb-2 col-12 col-md-6" style="padding-left: 0">
                  <b-form-input type="text" v-model="input.filter" placeholder="Search by wallet address"></b-form-input>
                </div>
                <b-table
                  id="staked-heroes"
                  :items="winners"
                  :fields="table.columns"
                  :per-page="table.perPage"
                  :current-page="table.currentPage"
                  striped hover bordered
                  :sort-by.sync="table.sortBy"
                  :sort-desc.sync="table.sortDesc"
                >
                  <template #cell(hero)="data">
                    <a :href="`https://faraland.io/hero/${data.item.hero}`" target="_blank">{{data.item.hero}}</a>
                  </template>
                  <template #cell(race)="data">
                    <b-badge variant="primary">{{data.item.race}}</b-badge>
                  </template>
                  <template #cell(address)="data">{{formatAddress(data.item.address)}}</template>

                </b-table>
              </div>
              <div class="col col-12 ml-2">
                <b-pagination
                  v-model="table.currentPage"
                  :total-rows="winners.length"
                  :per-page="table.perPage"
                  prev-text="Prev"
                  next-text="Next"
                  aria-controls="staked-heroes"
                ></b-pagination>
              </div>
            </div>
          </card>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-12">
          <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
            <template slot="header">
              <h4 class="card-title">Your heroes staked by Redkite</h4>
            </template>
            <div class="row">
              <div class="col col-12 ml-2">
                <b-table
                        id="staked-heroes"
                        :items="redkite.data"
                        :fields="redkite.columns"
                        :per-page="redkite.perPage"
                        :current-page="redkite.currentPage"
                        striped hover bordered
                >
                  <template #cell(id)="data">
                    <a :href="`https://faraland.io/hero/${data.value}`" target="_blank">{{data.value}}</a>
                  </template>

                  <template #cell(amount)="data">
                    {{formatFara(data.item.amount)}} Fara (APY : {{data.item.apy}}%)
                  </template>

                  <template #cell(reward)="data">
                    <b-badge class="mr-1" variant="primary">{{formatNumber(data.item.expEarned)}} EXP</b-badge>
                  </template>

                  <template #cell(lockedTime)="data">
                    <b-badge variant="primary">Unlocked Time: {{formatDate(data.item.lockedTime)}} (locked in {{data.item.lockedMonths}} months)</b-badge>
                  </template>
                </b-table>
              </div>
              <div class="col col-12 ml-2">
                <b-pagination
                        v-model="redkite.currentPage"
                        :total-rows="redkite.data.length"
                        :per-page="redkite.perPage"
                        prev-text="Prev"
                        next-text="Next"
                        aria-controls="redkite-staked-heroes"
                ></b-pagination>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import BigNumber from 'bignumber.js'
  import numeral from 'numeral'
  import Card from 'src/components/Cards/Card.vue'
  import Wallet from '../utils/Wallet'
  const GRAPHQL_API = 'https://queries-graphnode.faraland.io/subgraphs/name/edwardevans094/farastore-v9'
  const REDKITE_ADDRESS = '0xb9b9926DFb2C021FcD8F2366961186A2760716E3'
  const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
  const PAYLOAD_GRAPH_API_HEROES_BELONG_TO_ADDRESS = `query wallet {
  knights(
    first: 100
    skip: 0
    orderBy: idNumber
    where: {
      owner: "0x0000000000000000000000000000000000000000"
    }
  ) {
    id
  }}`

  export default {
    components: {
      Card
    },
    data () {
      return {
        input: {
          filter: ''
        },
        table: {
          sortBy: 'hero',
          sortDesc: false,
          perPage: 10,
          currentPage: 1,
          columns: [
            {
              key: 'hero',
              label: 'ID',
              sortable: true
            },
            {
              key: 'address',
              label: 'Wallet',
              sortable: true
            },
            {
              key: 'race',
              label: 'Race',
              sortable: true
            }
          ],
          data: [{address: '0x0b402B3e1336d8E0f84d7333622E87617b254F11', hero: 6569, race: 'Human'},
            {address: '0x0e89f05a10fB75641300193BE93DDB3464885EA1', hero: 342, race: 'Human'},
            {address: '0x4286419E11938Ab8EcdeC7D74438e1FC201153F4', hero: 101, race: 'Elf'},
            {address: '0x4be707DF3D526f523866510Cf5250BEd7674375A', hero: 6102, race: 'Human'},
            {address: '0xF33b55c5c0e7bB08679eD4D712d7ceC38F3D19e4', hero: 5137, race: 'Dragonborn'},
            {address: '0x9D367AD901dcBf629b67AB8AA1e9d0799e89fEf4', hero: 16619, race: 'Fairy'},
            {address: '0xFA036493ED31a2DB81ceEbCd6eBbdE9650c98644', hero: 17556, race: 'Human'},
            {address: '0x71F60cecdB60Be5EC2d081eBc1fd7aBAB73A01d6', hero: 16473, race: 'Human'},
            {address: '0x751D73E1fa608F9b80227A5F6a3E66F2EDA49d60', hero: 593, race: 'Orc'},
            {address: '0xC0571b2Cc9B21feFC1460851Ab78550D06402bB6', hero: 14807, race: 'Human'},
            {address: '0xe6144Fe9fC5267f361ef0980571981BdF2cA78E4', hero: 19291, race: 'Elf'},
            {address: '0xF9B4c9988A2367468d19251c5270D1A0fbF1AE66', hero: 5922, race: 'Fairy'},
            {address: '0xbD9402632Fad5CF14CbbfD893949E7ed7A72Ade5', hero: 293, race: 'Elf'},
            {address: '0x358E25cd4d7631eB874D25F4e1Ae4a14B0abb56E', hero: 8970, race: 'Human'},
            {address: '0x64148779Ba8ea60BB1928a38d88F7b8Fae9c86cB', hero: 15730, race: 'Human'},
            {address: '0xE356298C49f5F509599f503F11F7fF3a2E6609a2', hero: 6363, race: 'Human'},
            {address: '0x47D9d5F7d207C31208B9b04b99133D8b43D0fc94', hero: 2458, race: 'Human'},
            {address: '0x6C4a157e6363fB44CF9a3eDaABeec6657914F8A1', hero: 2403, race: 'Elf'},
            {address: '0x17ba5976229aba6524055200067AaA282D0FA5CB', hero: 8498, race: 'Orc'},
            {address: '0x39B946e35b3DC215455cc95b446eDd8368928d10', hero: 9099, race: 'Orc'},
            {address: '0xE36D27471EA563698E5056AF5538CB98426C5546', hero: 36, race: 'Orc'},
            {address: '0x9BAD2f2C153eD24ae7399cdc94eD6964E3e305Fd', hero: 719, race: 'Dragonborn'},
            {address: '0x79CcA15aCdD26B41Ee96616896eB20ceBD3F2617', hero: 1172, race: 'Orc'},
            {address: '0xd7B821Ff90C4b4f9a56E93Bd6efe81bf65b439a1', hero: 4038, race: 'Elf'},
            {address: '0x5B73163920f9090079F1B7bCe7e01E149586961b', hero: 6363, race: 'Human'},
            {address: '0x392A9C7583d644F4f2235653850191a27A5728e9', hero: 7632, race: 'Elf'},
            {address: '0x6B04B505de68dfA59F6753C39A6a77D35fB7759b', hero: 8691, race: 'Orc'},
            {address: '0x096cCdC8E0362d901333fDfAe583901788f9D8C9', hero: 16282, race: 'Dragonborn'},
            {address: '0xCdc5ba2E9bC26DAD3b0a117bf93A49aA44B205a3', hero: 17955, race: 'Orc'},
            {address: '0x202FE0876F8195B6933cc94543d1C5E130664ee7', hero: 850, race: 'Orc'},
            {address: '0x148AFbce5CE5417e966E92D2c04Bd81D8cB0e04e', hero: 8558, race: 'Elf'},
            {address: '0x18f4ecbBB0bcF7832B711DC357a8F6B3F8f0BCA4', hero: 10393, race: 'Fairy'},
            {address: '0xD1453C1310846EC5Ba080fCb1D3E128e9D124745', hero: 11147, race: 'Human'},
            {address: '0x8DDC68AceAd3fd4442B26853Fe5F895885459CD7', hero: 11286, race: 'Elf'},
            {address: '0x7cB828A7B90FA88BE0C5Cb45bA4646D9BE0a28D9', hero: 12229, race: 'Orc'},
            {address: '0x03b9766467a4FEA1D38Df6c55EC4e1ff2AAdf522', hero: 15756, race: 'Orc'},
            {address: '0x4430Ba99074F595D0c550c61C0E4CaEf8994298B', hero: 16180, race: 'Dragonborn'},
            {address: '0x7F8a9D71c36f89574120103Ca4b9483d40897827', hero: 17883, race: 'Elf'}]
        },
        redkite: {
          perPage: 10,
          currentPage: 1,
          columns: [
            {
              key: 'id',
              label: 'ID',
              sortable: true
            },
            {
              key: 'amount',
              label: 'Amount',
              sortable: true
            },
            {
              key: 'reward',
              label: 'Reward',
              sortable: true
            },
            {
              key: 'lockedTime',
              label: 'Package',
              sortable: true
            }
          ],
          data: []
        }
      }
    },
    computed: {
      address () {
        return this.$store.state.wallet.address
      },
      fara () {
        return this.$store.state.wallet.fara
      },
      bnb () {
        return this.$store.state.wallet.bnb
      },
      web3 () {
        return this.$store.state.web3
      },
      winners () {
        let filter = this.input.filter.toLowerCase()
        if (!filter) {
          return this.table.data
        }

        return this.table.data.filter((item) => {
          return item.address.toLowerCase().includes(filter) || item.race.toLowerCase().includes(filter)
        })
      }
    },
    methods: {
      notify(message, color, horizontalAlign, verticalAlign) {
        this.$notifications.notify({
          message: `<span>${message}</span>`,
          icon: 'nc-icon nc-bell-55',
          horizontalAlign: horizontalAlign ? horizontalAlign : 'bottom',
          verticalAlign: verticalAlign ? verticalAlign : 'left',
          type: color ? color :  'success'
        })
      },
      fetchKnightsBelongToAddress(address) {
        if (!address) {
          return
        }

        let query = PAYLOAD_GRAPH_API_HEROES_BELONG_TO_ADDRESS.replaceAll(ZERO_ADDRESS, address.toLowerCase())
        fetch(GRAPHQL_API, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({query: query})
        }).then(response => response.json())
          .then(async (response) => {
            if (!response || !response.data || !response.data.knights || !response.data.knights.length) {
              return
            }

            for (const item of response.data.knights) {
              if (!item || !item.id) {
                continue
              }

              const id = item.id
              const data = await Wallet.stakingData(this.web3, REDKITE_ADDRESS, id)
              const amount = new BigNumber(data.balance ?? 0) / new BigNumber(1e9) / new BigNumber(1e9)
              if (!amount || amount <= 0) {
                continue
              }

              const rewardData = await Wallet.earned(this.web3, REDKITE_ADDRESS, id)
              this.pushRedkiteData({
                id: id,
                apy: data.APY ? parseInt(data.APY) : 0,
                amount: amount,
                lockedMonths: data.lockedMonths ? parseInt(data.lockedMonths) : 0,
                lockedTime: data.lockedTime ? parseInt(data.lockedTime) : 0,
                expEarned: new BigNumber(rewardData.expEarned ?? 0) / new BigNumber(1e9) / new BigNumber(1e9),
                tokenEarned: new BigNumber(rewardData.tokenEarned ?? 0) / new BigNumber(1e9) / new BigNumber(1e9)
              })
            }
          })
          .catch((e) => {
            let message = e
            if (e && e.message) {
              message = e.message
            }
            this.notify(message, 'danger')
          })
      },
      formatDate (timestamp) {
        if (!timestamp) {
          return ''
        }
        const date = new Date(timestamp * 1000)

        return`${date.getFullYear().toString().padStart(4, '0')}-${
          (date.getMonth()+1).toString().padStart(2, '0')}-${
          date.getDate().toString().padStart(2, '0')} ${
          date.getHours().toString().padStart(2, '0')}:${
          date.getMinutes().toString().padStart(2, '0')}:${
          date.getSeconds().toString().padStart(2, '0')}`
      },
      formatNumber (number) {
        if (!number) {
          return 0
        }

        return numeral(number).format('0,0.000')
      },
      formatFara (number) {
        if (!number) {
          return 0
        }

        return numeral(number).format('0,0')
      },
      formatAddress (address) {
        if (!address) {
          return ''
        }

        const length = 8

        return `${address.substr(0, length)}...${address.substr(address.length - length, length)}`
      },
      pushData (data) {
        if (this.table.data.find(element => element.id === data.id)) {
          return
        }

        this.table.data.push(data)
      },
      pushRedkiteData (data) {
        if (this.redkite.data.find(element => element.id === data.id)) {
          return
        }

        this.redkite.data.push(data)
      }
    },
    watch: {
      address (newAddress) {
        this.fetchKnightsBelongToAddress(newAddress)
      }
    },
    mounted () {
    }
  }

</script>
<style>
</style>
