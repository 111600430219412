<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <div class="navbar-collapse justify-content-end">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a v-if="connected" class="nav-link" :href="'https://bscscan.com/address/' + originalAddress" target="_blank">
              <span>{{ bnb }}</span>
              <img class="token"  src="../assets/bnb.svg" alt="">
              <span>{{ fara }}</span>
              <img class="token" src="../assets/fara.png" alt="">
              <span class="d-lg-block">{{ address }}</span>
            </a>
            <button v-else-if="!connected && !errorMessage" type="submit" class="btn btn-info btn-fill mr-1" @click.prevent="connect">
              Connect
            </button>
            <p v-else class="text-danger">
              {{errorMessage}}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
  import Web3Modal from "web3modal"
  const providerOptions = {}

  const web3Modal = new Web3Modal({
    network: "mainnet", // optional
    cacheProvider: true, // optional
    providerOptions // required
  })

  export default {
    computed: {
      routeName () {
        const {name} = this.$route
        return this.capitalizeFirstLetter(name)
      },
      connected () {
        return this.$store.state.wallet.connected
      },
      address () {
        const address = this.$store.state.wallet.address
        if (!address) {
          return ''
        }

        return `${address.substring(0, 6)}...${address.substring(address.length - 6, address.length)}`
      },
      originalAddress () {
        const address = this.$store.state.wallet.address
        if (!address) {
          return ''
        }

        return address
      },
      bnb () {
        return this.$store.state.wallet.bnb
      },
      fara () {
        return this.$store.state.wallet.fara
      },
      errorMessage () {
        if (this.$store.state.wallet.connected) {
          return ''
        }

        return this.$store.state.wallet.errorMessage
      },
      showSideBar () {
        return this.$sidebar.showSidebar
      }
    },
    data () {
      return {
        activeNotifications: false,
        provider: null,
      }
    },
    methods: {
      async connect() {
        console.log('connect')
        try {
          const provider = await web3Modal.connect()
          this.$store.dispatch('connectProvider', provider)
        }catch (e) {
          console.log('cannot connect')
        }
      },
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown () {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown () {
        this.activeNotifications = false
      },
      toggleSidebar () {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar () {
        this.$sidebar.displaySidebar(false)
      }
    },
    mounted() {
      if (web3Modal && web3Modal.cachedProvider) {
        this.connect()
      }
    }
  }

</script>
<style>
  .token {
    width: 20px;
    height: 20px;
    margin-left: 3px;
    margin-right: 3px;
  }
</style>
