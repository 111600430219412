import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import App from './App.vue'
import Web3 from 'web3'
import LightBootstrap from './light-bootstrap-main'
import BigNumber from 'bignumber.js'
import VueLazyload from 'vue-lazyload'
import Wallet from './utils/Wallet'

const loadingImage = require('./assets/loading.svg')
const errorImage = ''

Vue.config.productionTip = false
const BSC_MAINNET_ID = '0x38'
// const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
const ZERO_ADDRESS = ''
const WRONG_CHAIN_MESSAGE = 'Wrong chain. Please use Binance Smart Chain'
const clearStore = (state, message) => {
  if (!state) {
    return
  }

  state.wallet.bnb = 0
  state.wallet.fara = 0
  state.wallet.address = ZERO_ADDRESS
  state.wallet.connected = false
  if (message) {
    state.wallet.errorMessage = message
  } else {
    state.wallet.errorMessage = ''
  }
}

const setStoreAddress = (state) => {
  if (!state || !state.provider) {
    return
  }

  state.wallet.address = state.provider.selectedAddress
  state.wallet.connected = true
  state.wallet.errorMessage = ''
  if (!state.web3) {
    state.web3 = new Web3(state.provider)
  }

  Wallet.getBNBBalance(state.web3, state.wallet.address).then((balance) => {
    state.wallet.bnb = (new BigNumber(balance) / new BigNumber(1e9) / new BigNumber(1e9)).toFixed(4)
  })

  Wallet.getFaraBalance(state.web3, state.wallet.address).then((balance) => {
    state.wallet.fara = (new BigNumber(balance) / new BigNumber(1e9) / new BigNumber(1e9)).toFixed(4)
  })

  Wallet.allowance(state.web3, state.wallet.address).then((amount) => {
    state.wallet.approved = new BigNumber(amount).gt(new BigNumber(0))
  })
}

// router setup
import routes from './routes/routes'
// import Web3 from "web3";

// plugin setup
Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(LightBootstrap)
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: errorImage,
  loading: loadingImage,
  attempt: 2
})

const store = new Vuex.Store({
  state: {
    wallet: {
      address: ZERO_ADDRESS,
      bnb: 0,
      fara: 0,
      connected: false,
      errorMessage: '',
      approved: false
    },
    provider: null,
    web3: null
  },
  getters: {
    clear (state, message) {
      state.wallet.bnb = 0
      state.wallet.fara = 0
      state.wallet.address = ZERO_ADDRESS
      state.wallet.connected = false
      if (message) {
        state.wallet.errorMessage = message
      } else {
        state.wallet.errorMessage = ''
      }
    }
  },
  mutations: {
    changeApprove (state) {
      state.wallet.approved = true
    },
    changeAddress (state) {
      setStoreAddress(state)
    },
    changeBNB (state, amount) {
      state.wallet.bnb = amount
    },
    changeFara (state, amount) {
      state.wallet.fara = amount
    },
    connectProvider (state, provider) {
      if (!provider) {
        return
      }

      state.provider = provider
      if (provider.chainId === BSC_MAINNET_ID) {
        setStoreAddress(state)
      } else {
        clearStore(state, WRONG_CHAIN_MESSAGE)
      }

      state.provider.on("accountsChanged", () => {
        if (state.provider.chainId !== BSC_MAINNET_ID) {
          clearStore(state, WRONG_CHAIN_MESSAGE)
          return
        }

        setStoreAddress(state)
      })

      state.provider.on("chainChanged", (info) => {
        if (info !== BSC_MAINNET_ID) {
          clearStore(state, WRONG_CHAIN_MESSAGE)
          return
        }

        setStoreAddress(state)
      })

      state.provider.on("connect", (info) => {
        if (info.chainId !== BSC_MAINNET_ID) {
          clearStore(state, WRONG_CHAIN_MESSAGE)
          return
        }

        setStoreAddress(state)
      })

      state.provider.on("disconnect", () => {
        clearStore(state)
      })
    },
    reloadBalance (state) {
      setStoreAddress(state)
    },
    disconnect (state) {
      clearStore(state)
    }
  },
  actions: {
    changeAddress (context, address) {
      context.commit('changeAddress', address)
    },
    changeBNB (context, amount) {
      context.commit('changeBNB', amount)
    },
    changeFara (context, amount) {
      context.commit('changeFara', amount)
    },
    changeApprove (context) {
      context.commit('changeApprove')
    },
    connectProvider (context, provider) {
      context.commit('connectProvider', provider)
    },
    reloadBalance (context) {
      context.commit('reloadBalance')
    },
    disconnect (context) {
      context.commit('disconnect')
    }
  }
})

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'nav-item active',
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
})

new Vue({
  el: '#app',
  store,
  render: h => h(App),
  router
})
