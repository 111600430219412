<template>
  <footer class="footer">
    <div class="container-fluid">
      <nav>
      </nav>
<!--      <div class="copyright text-center">-->
<!--        &copy; Coded with-->
<!--        <i class="fa fa-heart heart"></i> by-->
<!--        <a href="https://binarcode.com" target="_blank">BinarCode</a>.-->
<!--        Designed by <a href="https://www.creative-tim.com/" target="_blank">Creative Tim</a>.-->
<!--      </div>-->
    </div>
  </footer>
</template>
<script>
  export default {}

</script>
<style>

</style>
